import React from "react";
import Layout from "../../Layout";
import SEO from "../../seo";
import "./style.scss";

export default function FeeStructurePage() {
  const feeData = [
    {
      class: "NUR-PREP",
      tuitionFee: 5738,
      annualCharges: 1624,
      developmentFee: 861,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 8704,
    },
    {
      class: "I TO III",
      tuitionFee: 6034,
      annualCharges: 1624,
      developmentFee: 905,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 9044,
    },
    {
      class: "IV",
      tuitionFee: 6833,
      annualCharges: 1624,
      developmentFee: 1025,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 9963,
    },
    {
      class: "V-VI",
      tuitionFee: 6913,
      annualCharges: 1624,
      developmentFee: 1037,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 10055,
    },
    {
      class: "VII-X",
      tuitionFee: 7808,
      annualCharges: 1624,
      developmentFee: 1171,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 11084,
    },
    {
      class: "XI-XII-NON SC",
      tuitionFee: 7952,
      annualCharges: 1690,
      developmentFee: 1193,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 11316,
    },
    {
      class: "XI-XII-SC",
      tuitionFee: 8935,
      annualCharges: 1690,
      developmentFee: 1340,
      activity: 281,
      portalSmartClass: 200,
      totalMonthlyFee: 12446,
    },
  ];

  return (
    <Layout>
      <SEO
        title="Fees Structure Chinmaya Vidayalaya Delhi CBSE School"
        description="Know more about the Chinmaya Vidyalaya cbse school fees structure."
        keywords="chinmaya vidyalaya fees structure"
      />
      <div className="feestructure">
        <div className="feestructure__header">
          <h2 className="heading">Fee Structure</h2>
          <h3>(As per Fee Structure 2024-25)</h3>
        </div>

        <div className="feestructure__table-container">
          <table className="feestructure__table">
            <thead>
              <tr>
                <th>CLASS</th>
                <th>TUITION FEE</th>
                <th>ANNUAL CHARGES</th>
                <th>DEVELOPMENT FEE</th>
                <th>ACTIVITY</th>
                <th>PORTAL & SMART CLASS</th>
                <th>TOTAL MONTHLY FEE</th>
              </tr>
            </thead>
            <tbody>
              {feeData.map((row) => (
                <tr key={row.class}>
                  <td>{row.class}</td>
                  <td>{row.tuitionFee.toLocaleString()}</td>
                  <td>{row.annualCharges.toLocaleString()}</td>
                  <td>{row.developmentFee.toLocaleString()}</td>
                  <td>{row.activity.toLocaleString()}</td>
                  <td>{row.portalSmartClass.toLocaleString()}</td>
                  <td>{row.totalMonthlyFee.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}
